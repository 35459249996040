import * as React from 'react';
import { Container, Box, Typography } from '@mui/material';

import ServiceCard from './ServiceCard';
import TeamsChips from './TeamsChips';

import { UseCases, Models, Pocs, UseCase, Model, Poc } from '../types';


interface DisplayCardProps {
    title: string
    services: UseCases | Models | Pocs;
    onEdit: (service: UseCase | Model | Poc) => void;
    onDelete: (id: string) => void;
}

const CardDisplay: React.FC<DisplayCardProps> = ({ title, services, onEdit, onDelete }) => {
    return (
        <Box>
            <Box sx={{
                backgroundColor: 'white',
                // marginTop: 4,
                paddingTop: 8,
                paddingBottom: 3,
                // position: 'fixed',
                // width: '100%',
                // zIndex: (theme) => theme.zIndex.drawer
            }}>
                <Typography variant="h4" sx={{ml: 5 }}>
                    {title}
                </Typography>
            </Box>
            <Box>
                <Box className="chips" sx={{ ml: 5, mt: 4 }}>
                    <TeamsChips />
                </Box>
                <Box>
                    <div
                        className="cardDisplay"
                        style={{
                            // margin: "0 25%",
                            display: "flex",
                            flexDirection: "row",
                            textAlign: "left",
                            flexWrap: "wrap",
                            // border: "1px solid black",
                            padding: "2em",
                            // backgroundColor: "#f1f1f1",
                        }}
                    >
                        {services.map(uc => (
                            <ServiceCard key={uc.id} service={uc} onEdit={onEdit} onDelete={onDelete} />
                        ))}
                    </div>
                </Box>
                    {/* <button onClick={handleAddUseCase}>Add Use Case</button>
                    {selectedUseCase && (
                        <UseCaseForm
                            useCase={selectedUseCase}
                            onSave={handleSaveUseCase}
                            onCancel={() => setSelectedUseCase(null)}
                        />
                    )} */}
            </Box>
        </Box>
    );
};

export default CardDisplay;