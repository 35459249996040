import { blue, red, green, orange, pink } from '@mui/material/colors';

export type TeamKeys = 'negocio' | 'clientes' | 'procesos_clave' | 'tecnica' | 'operaciones';

export const teamColors: { [key in TeamKeys]: string } = {
    negocio: red[300],
    clientes: pink[300],
    procesos_clave: orange[300],
    tecnica: blue[300],
    operaciones: green[300],
}

export const teamInitials: { [key in TeamKeys]: string } = {
    negocio: "NE",
    clientes: "CL",
    procesos_clave: "PC",
    tecnica: "TE",
    operaciones: "OP",
}


export type StatusKeys = 'production' | 'development' | 'canceled' | 'blocked' | 'na';
export const statusColors: { [key in StatusKeys]: string } = {
    production: green[200],
    development: orange[200],
    canceled: red[200],
    blocked: red[200],
    na: '',
}