import React, { useState, useEffect } from 'react';
import CardDisplay from './components/CardDisplay';
import { UseCase, Model, Poc } from './types';


const PageModels: React.FC = () => {
    // MODELOS
    const [models, setModels] = useState<Model[]>([]);
    const [selectedModel, setSelectedModel] = useState<Model | null>(null);

    // Obtener casos de uso del backend
    useEffect(() => {
        fetch('/back-api/models')
            .then(res => res.json())
            .then(data => setModels(data))
            .catch(err => console.error('Error fetching models:', err));
    }, []);

    const handleEditModel = (model: Model) => {
        console.log("Quiero editar el " + model.id)
        setSelectedModel(model);
    };

    function handleService(service: UseCase | Model | Poc): void {
        console.log(service)
        if ('functional_dashboard' in service) {
          // Aquí TypeScript sabe que service es de tipo UseCase
          console.log('Quiero editar un Use Case')
          // Tu lógica con useCase
        } else {
          // Maneja el caso en que service sea de tipo Model o Poc
          console.log("Service is not a UseCase.");
        }
      }
    
    const handleDeleteUseCase = (id: string) => {
        fetch(`/back-api/models/${id}`, { method: 'DELETE' })
            .then(() => setModels(models.filter(uc => uc.id !== id)))
            .catch(err => console.error(err));
    };



    return (
        <CardDisplay title='Modelos' services={models} onEdit={handleService} onDelete={handleDeleteUseCase} />
    );
};

export default PageModels;