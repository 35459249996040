import * as React from 'react';
import { Avatar } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import {TeamKeys, teamColors, teamInitials} from '../commons';


interface TeamChipProps {
    team: TeamKeys;
}

const TeamChip: React.FC<TeamChipProps> = ({ team }) => {

    var initColor = teamColors[team];
    var label = teamInitials[team];

    const [disabled, setDisabled] = React.useState(false);
    const [color, setColor] = React.useState(initColor);

    const handleClick = () => {
        setColor(disabled? initColor : '');
        setDisabled(!disabled);
    };

    return (
        <Chip
            avatar={<Avatar sx={{ bgcolor: color }}>{label}</Avatar>}
            label={team}
            variant={disabled? 'filled': 'outlined'}
            onClick={handleClick}
        />
    )
}

export default function TeamsChips() {
    return (
        <Stack direction="row" spacing={1} flexWrap="wrap">
            {Object.keys(teamColors).map(t => (
                <TeamChip team={t as TeamKeys} />
            ))}
        </Stack>
    )
}