import React, { useState, useEffect } from 'react';
import CardDisplay from './components/CardDisplay';
import { UseCase, Model, Poc } from './types';

const PageBusinessCases: React.FC = () => {
    const [useCases, setUseCases] = useState<UseCase[]>([]);
    const [selectedUseCase, setSelectedUseCase] = useState<UseCase | null>(null);

    // Obtener casos de uso del backend
    useEffect(() => {
        fetch('/back-api/business-cases')
            .then(res => res.json())
            .then(data => setUseCases(data))
            .catch(err => console.error('Error fetching use cases:', err));
    }, []);

    // const handleAddUseCase = () => {
    //     setSelectedUseCase({ id: '', title: '', status: '', description: '', team: '', image: '' });
    // };

    const handleEditUseCase = (useCase: UseCase) => {
        console.log("Quiero editar el " + useCase.id)
        setSelectedUseCase(useCase);
    };

    const handleDeleteUseCase = (id: string) => {
        fetch(`/back-api/business-cases/${id}`, { method: 'DELETE' })
            .then(() => setUseCases(useCases.filter(uc => uc.id !== id)))
            .catch(err => console.error(err));
    };

    const handleSaveUseCase = (useCase: UseCase) => {
        const method = useCase.id ? 'PUT' : 'POST';
        const url = useCase.id ? `/back-api/business-cases/${useCase.id}` : '/back-api/business-cases';
        fetch(url, {
            method,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(useCase),
        })
        .then(res => res.json())
        .then(savedUseCase => {
            setUseCases(prevUseCases => {
                if (useCase.id) {
                    return prevUseCases.map(uc => (uc.id === savedUseCase.id ? savedUseCase : uc));
                } else {
                    return [...prevUseCases, savedUseCase];
                }
            });
            setSelectedUseCase(null);
        })
        .catch(err => console.error(err));
    };

    function handleService(service: UseCase | Model | Poc): void {
        console.log(service)
        if ('functional_dashboard' in service) {
          // Aquí TypeScript sabe que service es de tipo UseCase
          console.log('Quiero editar un Use Case')
          // Tu lógica con useCase
        } else {
          // Maneja el caso en que service sea de tipo Model o Poc
          console.log("Service is not a UseCase.");
        }
      }

    return (
        <CardDisplay title="Casos de uso" services={useCases} onEdit={handleService} onDelete={handleDeleteUseCase} />
    );
};

export default PageBusinessCases;