import React, { Suspense } from 'react';
import SearchAppBar from './components/SearchAppBar';
import CustomSpeedDial from './components/CustomSpeedDial';
import { BrowserRouter as Router } from 'react-router-dom';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';


// Crea tu tema personalizado
const theme = createTheme({
    palette: {
    //   primary: {
    //     main: '#D81E05', // Color principal
    //     // main: '#1976d2', // Color principal
    //   },
    //   secondary: {
    //     main: '#f50057', // Color secundario
    //   },
      background: {
        default: '#f9f9f9', // Color de fondo por defecto
      },
    },
    // typography: {
    //   fontFamily: 'Roboto, sans-serif', // Fuente por defecto
    //   h1: {
    //     fontSize: '2rem',
    //   },
    // },
  });

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback={<span>Loading component...</span>}>
                <Router>
                    <SearchAppBar/>
                    <CustomSpeedDial/>
                </Router>
            </Suspense>
        </ThemeProvider>
    );
};

export default App;