import { Avatar } from '@mui/material';
import {TeamKeys, teamColors, teamInitials} from '../commons';


interface TeamAvatarProps {
    team: string
}
const TeamAvatar: React.FC<TeamAvatarProps> = ({ team }) => {
    var color = teamColors[team as TeamKeys]
    var initials = teamInitials[team as TeamKeys]
    return (
        <Avatar sx={{ bgcolor: color }} aria-label={"avatar-"+{team}}>
            {initials}
        </Avatar>
    )
}

export default TeamAvatar