import * as React from 'react';
import useMediaQuery from '@mui/material';
import { styled, alpha, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Container, Toolbar, IconButton, Typography, InputBase, Drawer, List, ListItem, ListItemButton, Divider, ListItemIcon, ListItemText  } from '@mui/material';

import { Route, Routes } from 'react-router-dom';
import ResponsiveDrawer from './ResponsiveDrawer';
import TeamsChips from './TeamsChips';
import PageModels from '../PageModels';
import PagePocs from '../PagePocs';
import PageBusinessCases from '../PageBusinessCases';
import PageRelations from '../PageRelations';
import PageDeploy from '../PageDeploy';


const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  // display: 'flex',
  alignItems: 'center',
  // justifyContent: 'space-between',
  // flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: theme.palette.divider,
  backgroundColor: alpha(theme.palette.background.default, 0.4),
  boxShadow: theme.shadows[1],
  padding: '0px 12px',
}));



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const drawerWidth = 240;


const drawer = (
  <div>
  <Toolbar />
    <Box sx={{ overflow: 'auto' }}>
    <List>
      <ListItem key='business-cases' disablePadding>
        <ListItemButton href='/business-cases'>
          <ListItemText primary='Casos de uso' />
          <ListItemIcon>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      <ListItem key='models' disablePadding>
        <ListItemButton href='/models'>
          <ListItemText primary='Modelos' />
          <ListItemIcon>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      <ListItem key='pocs' disablePadding>
        <ListItemButton href='/pocs'>
          <ListItemText primary='POCs' />
          <ListItemIcon>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      <ListItem key='relations' disablePadding>
        <ListItemButton href='/relations'>
          <ListItemText primary='Relaciones' />
          <ListItemIcon>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      <ListItem key='deploy' disablePadding>
        <ListItemButton href='/deploy'>
          <ListItemText primary='Deploy' />
          <ListItemIcon>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
    </List>
    <Divider />
    <List>
      {['IA & DATA 2024'].map((text, index) => (
        <ListItem key={text}>
          <ListItemText primary={text} />
        </ListItem>
      ))}
    </List>
  </Box>
  </div>
);




export default function SearchAppBar() {
  
  const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isClosing, setIsClosing] = React.useState(false);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setMobileOpen(false);
    };
    
      const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };
    
    const handleDrawerToggle = () => {
        if (!isClosing) {
          setMobileOpen(!mobileOpen);
        }
    };
  

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1
        }}
      >
          <Toolbar variant='regular'>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              IA & DATA
            </Typography>
            <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
          </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
            keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
            flexShrink: 0,
            display: { xs: 'block', sm: 'none' },
            // display: 'block',
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
            {drawer}
        </Drawer>
      </Box>
      <Container className="App" maxWidth="xl" sx={{mt: 4, border: "0px solid black"}}>
          <Routes>
              <Route path="/" element={<PageBusinessCases/>} />
              <Route path="/models" element={<PageModels/>} />
              <Route path="/pocs" element={<PagePocs/>} />
              <Route path="/business-cases" element={<PageBusinessCases/>} />
              <Route path="/relations" element={<PageRelations/>} />
              <Route path="/deploy" element={<PageDeploy/>} />
          </Routes>
      </Container>
    </Box>
  );
}