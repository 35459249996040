import { Box, Typography } from '@mui/material';


const PageRelations: React.FC = () => {
    return (
        <Box id="relations" className="relations" sx={{ mt: 12, ml: 5 }}>
            <Typography variant='h3'>
            Working on it...
            </Typography>
            <Typography variant='body1'>
            Pronto podrás ver aquí las relaciones entre Casos de Uso y modelos. Paciencia
            </Typography>
        </Box>
    );
};

export default PageRelations;