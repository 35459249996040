import * as React from 'react';
import { styled } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import { IconButtonProps } from '@mui/material/IconButton';
import Launch from '@mui/icons-material/Launch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Download, Share } from '@mui/icons-material';
import Edit from '@mui/icons-material/Edit'
import Delete from '@mui/icons-material/Delete'
import Divider from '@mui/material/Divider';


import { IconButton, Typography, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem  } from '@mui/material';
import { Card, CardContent, CardActions, Link, } from '@mui/material';

import { UseCase, Model, Poc } from '../types';
import TeamAvatar from './TeamAvatar';
import { StatusKeys, statusColors } from '../commons';
import { ThemeConsumer } from 'styled-components';


interface CustomCardMediaProps {
    service: UseCase | Model | Poc;
}
const CustomCardMedia: React.FC<CustomCardMediaProps> = ({ service }) => {
    var image=process.env.PUBLIC_URL + "/static/images/default_image_bc.png";
    var title="Servicio";
    var alt="Imagen de servicio";
    if (service.service_type === 'business-case') {
        image=process.env.PUBLIC_URL + "/static/images/default_image_bc.png"
        title="Caso de uso"
        alt="Imagen caso de uso"
    }
    else if (service.service_type === 'model') {
        image=process.env.PUBLIC_URL + "/static/images/default_image_model.png"
        title="Modelo"
        alt="Imagen modelo"
    }
    else if (service.service_type === 'poc') {
        image=process.env.PUBLIC_URL + "/static/images/default_image_poc.png"
        title="POC"
        alt="Imagen poc"
    }
    return (
        <CardMedia
            sx={{ height: 120 }}
            component="img"
            image={service.image? service.image : image}
            title={title}
            alt={alt}
        />
    )
}

interface ServiceStatusProps {
    status: string
}
const ServiceStatus: React.FC<ServiceStatusProps> = ({ status }) => {
    var color = statusColors[status as StatusKeys]
    return (
        <CardContent sx={{ margin: 0, padding: 1, paddingLeft: 4, backgroundColor: color }}>
            <Typography variant="body2" color="white">
                {status.toUpperCase()}
            </Typography>
        </CardContent>
    )
}


interface RepoLinkProps {
    service: UseCase | Model | Poc;
}
const RepoLink: React.FC<RepoLinkProps> = ({ service }) => {
    return (
        <ListItem key='repo_link' disablePadding>
            <ListItemButton href={service.repository_url} target="_blank" rel="noopener noreferrer">
            <ListItemIcon><Launch /></ListItemIcon>
            <ListItemText primary='Repo' />
            </ListItemButton>
        </ListItem>
    )
}

interface FunctionalDashboardProps {
    service: UseCase;
}
const FunctionalDashboard: React.FC<FunctionalDashboardProps> = ({ service }) => {
    var color = statusColors[service.functional_dashboard.status as StatusKeys]
    return (
        <ListItem key='functional_dashboard_link' disablePadding sx={{backgroundColor: {color}, fontWeight: 'bold'}}>
            <ListItemButton href={service.functional_dashboard.url} target="_blank" rel="noopener noreferrer">
            <ListItemIcon><Launch /></ListItemIcon>
            <ListItemText primary='Dashboard funcional' />
            </ListItemButton>
        </ListItem>
    )
}

interface TechnicalDashboardProps {
    service: UseCase | Model ;
}
const TechnicalDashboard: React.FC<TechnicalDashboardProps> = ({ service }) => {
    var color = statusColors[service.technical_dashboard.status as StatusKeys]
    return (
        <ListItem key='technical_dashboard_link' disablePadding sx={{backgroundColor: {color}, fontWeight: 'bold'}}>
            <ListItemButton href={service.technical_dashboard.url} target="_blank" rel="noopener noreferrer">
            <ListItemIcon><Launch /></ListItemIcon>
            <ListItemText primary='Dashboard técnico' />
            </ListItemButton>
        </ListItem>
    )
}


interface MainLinksProps {
    service: UseCase | Model | Poc;
}
const MainLinks: React.FC<MainLinksProps> = ({ service }) => {
    if (service.service_type === 'business-case') {
        return (
            <List>
                <RepoLink service={service} />
                <FunctionalDashboard service={service as UseCase} />
                <TechnicalDashboard service={service as UseCase} />
            </List>
        )
    }
    else if (service.service_type === 'model') {
        return (
            <List>
                <RepoLink service={service} />
                <TechnicalDashboard service={service as Model} />
            </List>
        )
    }
    else if (service.service_type === 'poc') {
        return (
            <List>
                <RepoLink service={service} />
            </List>
        )
    }
    else {
        return (<List></List>)
    }
}


interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
  }
  
const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme }) => ({
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    variants: [
      {
        props: ({ expand }) => !expand,
        style: {
          transform: 'rotate(0deg)',
        },
      },
      {
        props: ({ expand }) => !!expand,
        style: {
          transform: 'rotate(180deg)',
        },
      },
    ],
}));




interface CardProps {
    service: UseCase | Model | Poc;
    onEdit: (service: UseCase | Model | Poc) => void;
    onDelete: (id: string) => void;
}

const ServiceCard: React.FC<CardProps> = ({ service, onEdit, onDelete }) => {

    const [expanded, setExpanded] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Card sx={{ width: 340, marginRight: 2, marginBottom: 2 }}>
            <CardHeader
                avatar={<TeamAvatar team={service.team} />}
                action={
                <IconButton
                    aria-label="settings"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                }
                title={
                    <Typography variant="h6">
                        {service.title}
                    </Typography>
                }
                subheader={service.id}
            />
            <CustomCardMedia service={service} />
            <ServiceStatus status={service.status} />
            <CardContent>
                <MainLinks service={service} />
            </CardContent>
            <CardContent>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {service.short_description? service.short_description : '...'}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <ExpandMore
                    expand={expanded}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon />
                </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography sx={{ marginBottom: 2 }} variant='subtitle1'>Detalles:</Typography>
                    <Typography variant="body2">
                        {service.description}
                    </Typography>
                </CardContent>
            </Collapse>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Edit fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Editar</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Share fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Compartir</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Download fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Descargar</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Delete fontSize="small" sx={{color: 'red'}} />
                    </ListItemIcon>
                    <ListItemText sx={{color: 'red'}}>Eliminar</ListItemText>
                </MenuItem>
            </Menu>
            {/* <CardActions>
                <Button size="small" color="primary" onClick={() => onEdit(service)}>
                    Edit
                </Button>
                <Button size="small" color="secondary" onClick={() => onDelete(service.id)}>
                    Delete
                </Button>
            </CardActions> */}
        </Card>
    );
};

export default ServiceCard;