import React, { useState, useEffect } from 'react';
import CardDisplay from './components/CardDisplay';
import { UseCase, Model, Poc } from './types';

const PagePocs: React.FC = () => {
        // POCs
    const [pocs, setPocs] = useState<Poc[]>([]);
    const [selectedPoc, setSelectedPoc] = useState<Poc | null>(null);

    // Obtener casos de uso del backend
    useEffect(() => {
        fetch('/back-api/pocs')
            .then(res => res.json())
            .then(data => setPocs(data))
            .catch(err => console.error('Error fetching pocs:', err));
    }, []);

    const handleEditPoc = (poc: Poc) => {
        console.log("Quiero editar el " + poc.id)
        setSelectedPoc(poc);
    };


    function handleService(service: UseCase | Model | Poc): void {
        console.log(service)
        if ('functional_dashboard' in service) {
          // Aquí TypeScript sabe que service es de tipo UseCase
          console.log('Quiero editar un Use Case')
          // Tu lógica con useCase
        } else {
          // Maneja el caso en que service sea de tipo Model o Poc
          console.log("Service is not a UseCase.");
        }
      }
    
    const handleDeleteUseCase = (id: string) => {
        fetch(`/back-api/pocs/${id}`, { method: 'DELETE' })
            .then(() => setPocs(pocs.filter(uc => uc.id !== id)))
            .catch(err => console.error(err));
    };



    return (
        <CardDisplay title='Demos y POCs' services={pocs} onEdit={handleService} onDelete={handleDeleteUseCase} />
    );
};

export default PagePocs;