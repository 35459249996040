import { Box, Typography } from '@mui/material';


const PageDeploy: React.FC = () => {
    return (
        <Box id="deploy" className="deploy" sx={{ mt: 12, ml: 5 }}>
            <Typography variant='h3'>
            Working on it...
            </Typography>
            <Typography variant='body1'>
            Pronto podrás desplegar APIs desde aquí. Paciencia
            </Typography>
        </Box>
    );
};

export default PageDeploy;